import { createStore } from "vuex";
import { auth } from "../../../../breednet-blackbook-frontend/src/store/auth.module";

const store = createStore({
  modules: {
    auth,
  },
});

export default store;
