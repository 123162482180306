class Converter {
    stringToBoolean(string) {
        if (string == undefined) {
            string = "false"
        }

        switch (string.toString().toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: case undefined: return false;
            default: return Boolean(string);
        }
    }
}
export default new Converter() 