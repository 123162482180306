import axios from 'axios';
import converter from '../functions/converter.js'
import globalVars from '../functions/globalVars.js';
import authHeader from './auth-header';

axios.defaults.withCredentials = true

const endPoint = globalVars.getApiEndPoint();
const API_URL = endPoint + '/api/accounts/';

class AuthService {
  login(user) {
    var rem = converter.stringToBoolean(user.rememberMe)
    return axios
      .post(API_URL + 'login', {
        email: user.email,
        password: user.password,
        rememberMe: rem
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
    
  }

  logout() {
    axios.post(API_URL + 'logout')
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'breednetuserregister', {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
      postcode: user.postcode
    });
  }
  
  updateUserDetails(userDetails){
    var data = new FormData();
    data.append("Firstname", userDetails.firstname);
    data.append("Lastname", userDetails.lastname);
    data.append("Email", userDetails.email);
    data.append("Postcode", userDetails.postcode);
    data.append("PhoneNumber", userDetails.phoneNumber);
    data.append("BlackbookEmail", userDetails.blackbookEmail);
    data.append("Id", userDetails.id);

    return axios.post(API_URL + 'breednetupdateuser', data, { headers: authHeader() });

  }

  updateUserPassword(newPassword){
    var data = new FormData();
    data.append("Password", newPassword.password);
    data.append("PasswordConfirm", newPassword.confirmPassword);
    data.append("Id", newPassword.id);

    return axios.post(API_URL + 'breednetupdatepassword', data, { headers: authHeader() });

  }

  forgotPassword(user){
    var data = new FormData();
    data.append("Email", user.email);
    return axios.post(API_URL + 'forgotpassword', data, { headers: authHeader() });
  }

  check() {
    return axios.get(API_URL + 'check', { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }
}

export default new AuthService();
