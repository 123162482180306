<template>
  <div class="dropdown" v-if="currentUser" :key="reloadUser">
    <span class="dropdown-toggle"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="15px"
        height="17px"
        viewBox="0 0 15 17"
        version="1.1"
      >
        <g id="surface1">
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 0.5;
            "
            d="M 10.5 9.5625 C 9.539062 9.5625 9.078125 10.09375 7.5 10.09375 C 5.921875 10.09375 5.464844 9.5625 4.5 9.5625 C 2.015625 9.5625 0 11.5625 0 14.023438 L 0 15.40625 C 0 16.285156 0.71875 17 1.605469 17 L 13.394531 17 C 14.28125 17 15 16.285156 15 15.40625 L 15 14.023438 C 15 11.5625 12.984375 9.5625 10.5 9.5625 Z M 13.929688 15.40625 C 13.929688 15.699219 13.6875 15.9375 13.394531 15.9375 L 1.605469 15.9375 C 1.3125 15.9375 1.070312 15.699219 1.070312 15.40625 L 1.070312 14.023438 C 1.070312 12.148438 2.609375 10.625 4.5 10.625 C 5.15625 10.625 5.808594 11.15625 7.5 11.15625 C 9.1875 11.15625 9.84375 10.625 10.5 10.625 C 12.390625 10.625 13.929688 12.148438 13.929688 14.023438 Z M 7.5 8.5 C 9.867188 8.5 11.785156 6.597656 11.785156 4.25 C 11.785156 1.902344 9.867188 0 7.5 0 C 5.132812 0 3.214844 1.902344 3.214844 4.25 C 3.214844 6.597656 5.132812 8.5 7.5 8.5 Z M 7.5 1.0625 C 9.269531 1.0625 10.714844 2.492188 10.714844 4.25 C 10.714844 6.007812 9.269531 7.4375 7.5 7.4375 C 5.730469 7.4375 4.285156 6.007812 4.285156 4.25 C 4.285156 2.492188 5.730469 1.0625 7.5 1.0625 Z M 7.5 1.0625 "
          />
        </g>
      </svg>
      {{ fullName }}</span
    >
    <div class="dropdown-content">
      <a class="dropdown-item" href="/blackbook/blackbook-main">Blackbook</a>
      <a class="dropdown-item" href="/marketplace/sales-data-products"
        >Marketplace</a
      >
      <a class="dropdown-item" href="/blackbook/profile">Update Details</a>
      <a class="dropdown-item" @click.prevent="logOut">Logout</a>
    </div>
  </div>
  <div class="dropdown" v-if="!currentUser" :key="reloadUser">
    <span>Account</span>
    <div class="dropdown-content">
      <a class="dropdown-item" href="/blackbook/">Blackbook</a>
      <a class="dropdown-item" href="/marketplace/">Marketplace</a>
    </div>
  </div>
  <!-- <li class="nav-item" v-if="!currentUser" :key="reloadUser">
    <a class="nav-link" href="/blackbook/">Blackbook</a>
    <a class="nav-link" href="/marketplace/">Marketplace</a>
  </li> -->
</template>

<script>
import store from "../store";
import AuthService from "../../../../breednet-blackbook-frontend/src/services/auth.service";
export default {
  store,
  name: "UserLogin",
  components: {},
  data() {
    return {
      fullName: "",
      reloadUser: 0,
    };
  },
  computed: {
    currentUser() {
      return store.state.auth.user;
    },
  },
  mounted() {
    if (this.currentUser) {
      this.fullName =
        Array.from(this.currentUser.firstName)[0].toUpperCase() +
        Array.from(this.currentUser.lastName)[0].toUpperCase();
    }
  },
  methods: {
    logOut() {
      AuthService.logout();
      //commit("logout");
      //this.$store.dispatch("auth/logout");
      //this.$router.push("https://www.breednet.com.au/");
      window.location.href = "/";

      this.reloadUser += 1;
    },
  },
};
</script>
<style scoped>
.btn-primary {
  background-color: #fff;
}

.user-icon::before {
  content: "\f007";
}
.dropdown {
  position: relative;
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 100;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #b1b0b0;
}
.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #fff;
  min-width: 10rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  z-index: 1000;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-content a {
  color: rgba(0, 0, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: #000;
  background-color: #f8f9fa;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-item {
  padding-left: 5px;
  font-size: 1rem;
}
.nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 100;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media screen and (max-width: 1199px) {
  .dropdown-content {
    position: static;
  }
  .dropdown,
  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown {
    border: none;
  }
}
</style>
