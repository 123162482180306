export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    
    return {
      Authorization: 'Bearer ' + user.token,
      withCredentials: true,
      "Content-Type": "application/x-www-form-urlencoded",
    }; // for Spring Boot back-end
    // return { 'x-access-token': user.token };       // for Node.js Express back-end
  } else {
    return {};
  }
}
